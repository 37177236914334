<template>
  <editor v-on="$listeners" v-bind="$attrs" :init="init"/>
</template>

<script>
    /* Import TinyMCE */
    import 'tinymce';

    /* Default icons are required. After that, import custom icons if applicable */
    import 'tinymce/icons/default';

    /* Required TinyMCE components */
    import 'tinymce/themes/silver';
    import 'tinymce/models/dom';

    /* Import a skin (can be a custom skin instead of the default) */
    import 'tinymce/skins/ui/oxide/skin.css';

    /* Import plugins */
    import 'tinymce/plugins/advlist';
    import 'tinymce/plugins/autolink';
    import 'tinymce/plugins/link';
    import 'tinymce/plugins/lists';
    import 'tinymce/plugins/image';
    import 'tinymce/plugins/preview';
    import 'tinymce/plugins/image';
    import 'tinymce/plugins/media';
    import 'tinymce/plugins/charmap';
    import 'tinymce/plugins/anchor';
    import 'tinymce/plugins/searchreplace';
    import 'tinymce/plugins/visualblocks';
    import 'tinymce/plugins/code';
    import 'tinymce/plugins/fullscreen';
    import 'tinymce/plugins/insertdatetime';
    import 'tinymce/plugins/emoticons';
    import 'tinymce/plugins/emoticons/js/emojis';
    import 'tinymce/plugins/table';
    import 'tinymce/plugins/help';
    import 'tinymce/plugins/wordcount';
    /* Import premium plugins */
    /* NOTE: Download separately and add these to /src/plugins */
    /* import './plugins/checklist/plugin'; */
    /* import './plugins/powerpaste/plugin'; */
    /* import './plugins/powerpaste/js/wordimport'; */
    import "./zh_CN";
    import Editor from '@tinymce/tinymce-vue';
    import {directUploadTencentCloud} from "../../common/js/tencent_cloud";

    export default {
        name: "tiny-editor",
        components: {
            Editor
        },
        data() {
            return {
                init: {
                    height: 500,
                    toolbar_mode: 'wrap',
                    theme: 'silver',
                    menubar: 'file edit view insert format tools table tc help',
                    language: 'zh_CN',
                    default_font_size: '14px',
                    images_upload_handler: this.uploadHandler,
                    // file_picker_callback: this.filePickerCallback,
                    // video_template_callback: this.videoTemplateCallback,
                    file_picker_types: 'media',
                    line_height_formats: '1 1.1 1.2 1.3 1.4 1.5 1.6 1.75 1.8 2 3 4',
                    font_size_formats: "12px 14px 16px 18px 20px 24px 28px 32px 36px 40px 48px 54px",
                    font_family_formats:"微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=file antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings",

                    plugins: ['image', 'link', 'lists', 'charmap', 'preview', 'fullscreen'],
                    toolbar: [
                        { name: 'history', items: [ 'undo', 'redo' ] },
                        { name: 'styles', items: [ 'styles' ] },
                        { name: 'formatting', items: [ 'fontfamily', 'fontsize', 'lineheight', 'bold', 'italic', 'underline', 'strikethrough' ] },
                        { name: 'alignment', items: [ 'alignleft', 'aligncenter', 'alignright', 'alignjustify' ] },
                        { name: 'indentation', items: [ 'outdent', 'indent' ] },
                        { name: 'media', items: [ 'image' ] },
                        { name: 'other', items: ['link', 'lists', 'charmap' ] },
                        { name: 'operate', items: [ 'removeformat', 'preview', 'fullscreen' ] },
                    ]
                    // toolbar: 'undo redo | bold italic underline strikethrough lineheight | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment'
                }
            }
        },
        methods: {
            uploadHandler(blobInfo, progress) {
                let file = new File([blobInfo.blob()], blobInfo.filename());
                return directUploadTencentCloud(file,p => {
                    progress(Math.round(p.percent * 100));
                }).then(data=>{
                    let path = "https://" + data.Location;
                    return path
                })
            },
        }
    }
</script>

<style>
  .tox-statusbar__branding {
    display: none;
  }
  .tox-tinymce {
    border: var(--border);
    border-radius: 0;
  }
</style>
