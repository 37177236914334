<template>
  <calendar ref="calendar" lunar :value="value" @month="val => current = val" style="background-color: transparent">
    <template v-slot:full="{day}">
      <div class="date-buddha-box" @click.stop.prevent="handleSelect(day)" v-if="showOutMonth || day.inMonth">
        <div class="flex-box align-center justify-between">
          <div class="buddha-date-info">
            <div class="buddha-date-num">{{day.day}}</div>
            <div class="buddha-date-lunar">{{day.lunar.lnongDate == "初一" ? `${day.lunar.lnongMonth}月` : day.lunar.lnongDate}}</div>
          </div>
          <slot name="date" v-bind="{day}" v-if="$scopedSlots.date"></slot>
        </div>
        <div class="date-buddha" v-if="dateMap[day.dateStr]" @click.stop>
          <div class="date-buddha-item" v-for="(b, i) in dateMap[day.dateStr]" :key="i">
            <a-popover trigger="click" v-if="b.cat == 1 && !optional">
              <div class="pop-buddha-name" slot="title">
                <span>{{b.name}}</span>
                <div class="txt-btn" @click="goBuddhismDetail(b)">{{ b.button }}<a-icon type="right" /></div>
              </div>
              <div class="date-buddha-ctx" slot="content">
                <div class="date-buddha-info">联系人：{{b.contact_person}}</div>
                <div class="date-buddha-info">联系方式：{{b.tel}}</div>
                <div class="date-buddha-info">付款情况：{{b.statusText}}</div>
                <div class="date-buddha-info" v-if="b.user_id">经手人：<open-data type="userName" :openid="b.user_id" /></div>
                <div class="date-buddha-cancel" v-if="b.cancel">
                  <a-button type="primary" size="small" @click="goBuddhismDetail(b)">取消佛事</a-button>
                </div>
              </div>
              <div class="date-buddha-name" :style="{backgroundColor: b.status == 6 ? '#ccc' : b.color}">
                {{b.name}}
                <a-icon :type="b.icon" class="date-buddha-state" v-if="b.icon != 'circle'" />
                <div class="buddha-state-circle" v-else-if="b.icon"></div>
              </div>
            </a-popover>
            <div class="date-buddha-name" :style="{backgroundColor: b.color}" v-else>{{b.name}}</div>
          </div>
        </div>
      </div>
    </template>
  </calendar>
</template>

<script>
    import calendar from "../../components/calendar/calendar";
    import {getFhList, getMonthBuddhismList, getMonthBuddhismMap, buddhismColors} from "../../common/buddha/buddhism";
    import {isBuddhaAdmin} from "../../common/constant/org";

    export default {
        name: "BuddhismCalendar",
        components: {
            calendar
        },
        props: {
            optional: Boolean,
            value: String,
            showOutMonth: Boolean,
        },
        data() {
            return {
                current: null,
                pujaList: null,
                buddhaList: null,
                colors: buddhismColors,
                dateMap: {},
                exporting: false,
                admin: isBuddhaAdmin(),
            }
        },
        watch: {
            current(val) {
                this.getMonthBuddhismList();
                this.$emit("month", val);
            },
        },
        created() {
            this.getPujaList();
        },
        methods: {
            goBuddhismDetail(b) {
                this.$router.push({name: 'BuddhismDetail', params: {id: b.id}});
            },
            handleSelect(day) {
                if (this.optional) {
                    this.$emit("select", day);
                }
            },
            getPujaList() {
                getFhList().then(list => {
                    this.pujaList = list;
                    this.generateCalendarView();
                })
            },
            getMonthBuddhismList() {
                if(!this.current) return;
                getMonthBuddhismList(this.current).then(list => {
                    this.buddhaList = list;
                    this.generateCalendarView()
                })
            },
            generateCalendarView() {
                if(this.pujaList && this.buddhaList) {
                    this.dateMap = getMonthBuddhismMap(this.current, this.pujaList, this.buddhaList);
                }
            }
        }
    }
</script>

<style scoped lang="less">
  .export-buddhism {
    position: absolute;
    top: @padding-md;
    right: @padding-md;
    z-index: 1;
  }
  .date-buddha-box {
    display: flex;
    flex-direction: column;
    padding: 6px 12px;
    height: 100px;
  }
  .buddha-date-info {
    display: flex;
    justify-content: space-between;
  }
  .date-buddha {
    flex: 1;
    min-height: 0;
    margin-top: 8px;
    overflow: auto;
  }
  .date-buddha-item {
    color: @white;
    &:not(:first-of-type) {
      margin-top: 4px;
    }
  }
  .date-buddha-name {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  .date-buddha-state {
    margin-left: .5em;
  }
  .buddha-state-circle {
    margin-left: .5em;
    width: 1em;
    height: 1em;
    border-radius: 1em;
    border: 1px solid currentColor;
  }
  .date-buddha-ctx {
    font-size: 12px;
    .txt-btn {
      text-align: center;
    }
  }
  .date-buddha-info {
    margin-bottom: 6px;
  }
  .pop-buddha-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
    .txt-btn {
      font-size: 12px;
    }
  }
  .buddha-date-lunar {
    margin-left: 0.5em;
  }
  .date-buddha-cancel {
    margin-top: 8px;
    text-align: center;
  }
</style>
