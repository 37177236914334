const dev = false;
const localHost = 'http://lingyin.qihe.local';
const proHost = 'https://lingyin.zj-qq.cn';
const devHost = dev ? localHost : proHost;
const host = process.env.NODE_ENV === 'production' ? proHost : devHost

export default {
  host,
  token: null,
  CorpID: 'wwd1e47830fca25b7e',// 服务商CorpID
  SuiteID: 'ww466a95a2e61c03df',
  AgentId: 1000053
}
