<template>
    <div class="calendar-box">
        <slot name="top"></slot>
        <slot name="month" v-bind:month="month" v-if="$scopedSlots.month"></slot>
        <div class="calendar-month" :style="monthStyle" v-else-if="month">
            <div class="icon-double-arrow reduce" @click="setYear(-1)"></div>
            <div class="icon-arrow reduce" @click="setMonth(-1)"></div>
            <div class="month-text">{{monthStr}}</div>
            <div class="icon-arrow plus" @click="setMonth(1)"></div>
            <div class="icon-double-arrow plus" @click="setYear(1)"></div>
        </div>
        <table class="calendar-table">
            <thead class="t-head">
                <slot name="week" v-if="$slots.week"></slot>
                <tr class="tr week-day" :style="weekStyle" v-else>
                    <th class="td" :class="{weekend: i == 0 || i == 6}" v-for="(w, i) in weekdays" :key="i">{{ w }}</th>
                </tr>
            </thead>
            <tbody class="t-body">
                <tr class="tr" v-for="(tr, index) in table" :key="index">
                    <td class="td"
                        v-for="td in tr" :key="td.dateStr"
                        :style="typeof border === 'string' ? {border} : null"
                        :class="{weekend: td.weekday == 0 || td.weekday == 6, enable: td.enable, 'out-month': !td.inMonth, border: border === true}"
                         @click="selectDate(td)">
                        <slot name="full" v-bind="{day: td, index, length: table.length}" v-if="$scopedSlots.full"></slot>
                        <div class="date-ctx" :class="{now: td.isToday, select: select == td.dateStr}" v-else>
                            <div class="date-info">
                                <div class="date-num">{{td.day}}</div>
                                <div class="date-lunar" v-if="lunar">{{td.lunar.lnongDate == "初一" ? `${td.lunar.lnongMonth}月` : td.lunar.lnongDate}}</div>
                            </div>
                            <div class="date-other">
                                <slot name="date" v-bind:day="td"></slot>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import {getMonthDays, getLunarDay} from "./date"
    export default {
        name: "calendar",
        props: {
            value: String,
            lunar: Boolean,
            monthStyle: Object,
            weekStyle: Object,
            dealDay: Function,
            weekdays: {
                type: Array,
                default() {
                    return ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
                }
            },
            border: {
                type: [Boolean, String],
                default: true
            }
        },
        data() {
            return {
                table: [],
                select: null,
                month: null,
            }
        },
        watch: {
            value(val) {
                this.select = val;
                let month = new Date(val);
                month = new Date(month.getFullYear(), month.getMonth(), 1);
                this.table = this.getDateArray(month);
                this.month = month.getTime();
            },
            month(val) {
                this.$emit("month", val);
            },
            table(val) {
                this.$emit("table", val);
            }
        },
        computed: {
            monthStr() {
                return new Date(this.month).pattern("yyyy年M月");
            }
        },
        created() {
            let month;
            if(this.value) {
                this.select = this.value;
                month = new Date(this.value);
            } else {
                month = new Date();
            }
            month = new Date(month.getFullYear(), month.getMonth(), 1);
            this.table = this.getDateArray(month);
            this.month = month.getTime();
        },
        methods: {
            selectDate(date) {
                if(date.inMonth) {
                    this.select = date.dateStr;
                    this.$emit("change", date);
                }
            },
            setYear(num) {
                let year = new Date(this.month);
                year.setFullYear(year.getFullYear() + num);
                this.table = this.getDateArray(year);
                this.month = year.getTime();
            },
            setMonth(num) {
                let month = new Date(this.month);
                month.setMonth(month.getMonth() + num);
                this.month = month.getTime();
                this.table = this.getDateArray(month);
            },
            getDateArray: function (time) {
                let year = time.getFullYear();
                let month = time.getMonth();
                let firstDay = new Date(year, month, 1); //当月第一天
                let weekDay = firstDay.getDay(); //当月第一天是星期几
                let dayArray = getMonthDays(year);
                let today = new Date();
                today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                let weekNum = Math.ceil((dayArray[month] + weekDay) / 7); //当月显示周数
                let trList = [];
                for (let i = 0; i < weekNum; i++) {
                    let tdList = [];
                    for (let j = 0; j < 7; j++) {
                        let index = i * 7 + j; //表格单元的自然序号
                        let date = index - weekDay + 1; //计算日期
                        let dateObj, ty, tm, td;
                        let inMonth = false;
                        if (date <= 0) {
                            tm = month - 1 < 0 ? 11 : month - 1;
                            ty = month - 1 < 0 ? year - 1 : year;
                            td = dayArray[tm] + date;
                        } else if (date > dayArray[month]) {
                            tm = month + 1 > 11 ? 0 : month + 1;
                            ty = month + 1 > 11 ? year + 1 : year;
                            td = date - dayArray[month];
                        } else {
                            ty = year;
                            tm = month;
                            td = date;
                            inMonth = true;
                        }
                        const d = new Date(ty, tm, td);
                        const offset = d.getTime() - today.getTime();
                        let dateStr = this.getDateString(ty, tm, td);
                        dateObj = {
                            weekday: j,
                            day: td,
                            isToday: offset === 0,
                            dateStr: dateStr,
                            todayOffset: offset,
                            inMonth,
                            date: d
                        };
                        this.lunar && (dateObj.lunar = getLunarDay(dateObj.date));
                        let fn = this.dealDay;
                        if(typeof fn === 'function') {
                            fn(dateObj);
                        }
                        tdList.push(dateObj);
                    }
                    trList.push(tdList);
                }
                return trList;
            },
            getDateString: function (year, month, date) {
                return year + "-" + ((month + 1) < 10 ? ("0" + (month + 1)) : (month + 1)) + "-" + (date < 10 ? ("0" + date) : date);
            },
        }
    }
</script>

<style lang="less">
    .calendar-box {
        position: relative;
        background-color: #fff;
    }
    .calendar-month {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
        border: var(--border);
        border-bottom: 0;
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
    }
    .month-text {
        margin: 0 20px;
    }
    .reduce,
    .plus {
        width: 26px;
        height: 26px;
        font-size: 18px;
        text-align: center;
        line-height: 26px;
        color: @text-color-secondary;
        cursor: pointer;
        &:hover {
            background-color: @background-color-light;
        }
    }
    .plus {
        transform: rotate(180deg);
    }
    .calendar-table {
        width: 100%;
        text-align: center;
        table-layout: fixed;
        .t-head {
            border: var(--border);
            border-top: 0;
            border-bottom: 0;
        }
        .week-day {
            height: 48px;
            line-height:48px;
            font-size: 14px;
            font-weight: 600;
        }
        .td,
        .th {
            width: 14.2857142857%;
        }
        .t-body {
            .td {
                position: relative;
                cursor: pointer;
                &.border {
                    border: var(--border);
                }
                .date-ctx {
                    display: flex;
                    flex-direction: column;
                    height: 100px;
                    &.now {
                        color: @primary-color;
                    }
                    &.select {
                        background-color: @primary-color;
                        color: #fff;
                    }
                }
                .date-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 6px 12px;
                }
                .date-other {
                    flex: 1;
                    min-height: 0;
                    overflow: auto;
                }
                &.out-month {
                    color: #ccc;
                    cursor: auto;
                }
            }
        }
        .area {
            transition: height .3s;
            overflow: hidden;
        }
        .month {
            position: relative;
            &.animation {
                transition: transform .3s;
            }
            .prev,
            .next {
                position: absolute;
                left: 0;
                right: 0;
            }
            .prev {
                top: 0;
                transform: translateY(-100%);
            }
            .next {
                bottom: 0;
                transform: translateY(100%);
            }
        }
    }
</style>
