const state = {
    /* 应用缓存的路由 */
    include: [],
    /* 不缓存的路由 */
    exclude: [],
    paths: []
};

// getters
const getters = {
    include: (state) => state.include,
    exclude: (state) => state.exclude,
    paths: (state) => state.paths,
};

// mutations
const mutations = {
    setInclude(state, value) {
        state.include = value;
    },
    addInclude(state, value) {
        if(state.include.indexOf(value) === -1) {
            const include = [...state.include];
            include.push(value);
            if (include.length > 3) {
                include.shift();
            }
            state.include = include;
        }
    },
    removeInclude(state, value) {
        const index = state.include.indexOf(value);
        if(index >= 0) {
            const include = [...state.include];
            include.splice(index, 1);
            state.include = include;
        }
    },
    setExclude(state, value) {
        state.exclude = value;
    },
    updatePaths(state, value) {
        state.paths = value;
    },
};

const actions = {

};

export default {
    state,
    getters,
    mutations,
    actions
}
